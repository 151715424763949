import React, { useCallback, useEffect, useMemo } from 'react';

import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import DescriptionIcon from '@material-ui/icons/Description';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import { DecodedCTe } from '../../data-types/decoded-fiscal-documents-data';
import {
  selectCtesListState,
  selectNewShipmentReadCteIsRequestingState,
  selectNewShipmentReadCteErrorState,
} from '../../../features/Shipments/New/shipmentNewSlice';
import { UncontrolledFileUpload } from '../FileUpload';
import { useAppSelector } from '../../../app/hooks';
import AlertCard from '../AlertCard';
import { decimalNumber } from '../../helpers/mask.helper';

const useStyles = makeStyles({
  blueText: {
    color: '#1976D2',
  },
  clickableText: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  textColor: {
    color: '#606771',
  },
  title: {
    fontSize: 18,
    marginLeft: 15,
  },
});

interface CteFormFieldsProps {
  className?: string;
  onCleanRequests: () => void;
  onUpload: (files: File[]) => void;
}

const CteFormFields = ({
  className = '',
  onCleanRequests,
  onUpload,
}: CteFormFieldsProps) => {
  const classes = useStyles();

  const cteList = useAppSelector(selectCtesListState);
  const error = useAppSelector(selectNewShipmentReadCteErrorState);
  const loading = useAppSelector(selectNewShipmentReadCteIsRequestingState);

  const { setValue } = useFormContext();

  const ctes = useMemo(() => {
    const duplicatedCtes: { [key: string]: string[] } = {};
    const processedCtes: { [key: string]: boolean } = {};

    return cteList.map((cte) => {
      const newCte: DecodedCTe = {
        ...cte,
        issueDate: new Date(cte.issueDate),
      };

      const cteIdentifier = `${cte.number}-${cte.key}`;

      if (processedCtes[cteIdentifier]) {
        if (duplicatedCtes[cteIdentifier]) {
          duplicatedCtes[cteIdentifier].push(cteIdentifier);
        } else {
          duplicatedCtes[cteIdentifier] = [cteIdentifier];
        }

        newCte.isDuplicated = true;
      } else {
        processedCtes[cteIdentifier] = true;
        newCte.isDuplicated = false;
      }

      return {
        ...newCte,
      };
    });
  }, [cteList]);

  const duplicatedCount = useMemo(() => {
    return ctes.reduce((total, cte) => total + (cte.isDuplicated ? 1 : 0), 0);
  }, [ctes]);

  const totalValue = useMemo(() => {
    return cteList.reduce((acc, cte) => {
      if (cte.goodsValue !== undefined) {
        return acc + cte.goodsValue;
      }
      return acc;
    }, 0);
  }, [cteList]);

  const handleChange = useCallback(
    (newFiles: File[]) => {
      onCleanRequests();
      onUpload(newFiles);
    },
    [onCleanRequests, onUpload]
  );

  useEffect(() => {
    setValue('hasCTes', ctes.length >= 1);
    setValue('ctes', ctes);
  }, [ctes, setValue]);

  return (
    <Grid
      bgcolor="#FFFFFF"
      border={1}
      borderColor="#C2C3C9"
      borderRadius={3}
      className={className}
      component={Box}
      container
      direction="column"
      maxWidth={800}
      pb={3}
      pt={3}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        pb={2}
        pl={3}
        pr={3}
        pt={1}
      >
        <Box display="flex">
          <InsertDriveFileIcon className={classes.textColor} />
          <Typography className={`${classes.textColor} ${classes.title}`}>
            <strong>Insira os CTes</strong>
          </Typography>
        </Box>
      </Box>
      <Grid item component={Box} pl={3} pr={3}>
        <UncontrolledFileUpload
          accept=".xml"
          displayText="Arraste o arquivo XML aqui"
          error={!!error}
          loading={loading}
          multiple
          name="ctes"
          onChange={handleChange}
        />
      </Grid>
      {ctes.length > 0 && (
        <>
          <Box justifyContent="space-between" pb={2} pl={4} pr={4} pt={4}>
            <Grid container>
              <Grid item justifyContent="flex-start" xs={4}>
                <Typography className={`${classes.textColor} ${classes.title}`}>
                  <strong>CTes Adicionados</strong>
                </Typography>
              </Grid>
              <Grid item container xs={2} justifyContent="flex-end">
                <Grid>
                  <DescriptionIcon className={classes.textColor} />
                </Grid>
                <Grid>
                  <Typography className={classes.textColor}>
                    <strong>{ctes.length}</strong> <span>CTes</span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={6} justifyContent="flex-end">
                <Grid>
                  <Typography className={classes.textColor}>
                    <strong>Valor Total:</strong>
                    <span>{decimalNumber(totalValue)}</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          {ctes.some((cte) => cte.isDuplicated) && (
            <Grid item component={Box} pl={15} pr={15} pt={3}>
              <AlertCard severity="warning" title="CTes Duplicados">
                {duplicatedCount <= 1
                  ? `Na lista de documentos existe ${duplicatedCount} documento em
                duplicidade. esse documento será ignorados ao cadastrar.`
                  : `Na lista de documentos existem ${duplicatedCount} documentos em
                duplicidade. esses documentos serão ignorados ao cadastrar.`}
              </AlertCard>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default CteFormFields;
